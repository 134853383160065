/* Begin Basic Styleing
================================ */

body {
    color: $white;
    background-color: $white;
    font-weight: $weightRegular;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $primaryFont !important;
    height: 100%; /* Try removing this.. */
}
a {
    color: $primaryColor;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none !important;
    }
}

a, .btn, button {
    outline: none !important;
    
    &:hover,
    &:focus,
    &:active {
        outline: none !important;
    }
}

.wrapper {
    height: 100%;
    min-height: 100%;
    /* Try removing this.. */
    &.wrapper-full-page {
        height: 100%;
        min-height: 100vh;
        /* Try removing this.. */
    }
}

ul li,
ol li {
    font-weight: $weightRegular;
}
a,
a:link,
a:visited,
a:active {
    text-decoration: none;
}

.main{
    position: relative;
    background: $white;
}
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general{
    @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
    @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
    @include transform(rotate(180deg));
}

.button-bar{
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white;

    & + .button-bar{
        margin-top: 7px;
    }

    &:nth-child(2){
        width: 17px;
    }
}

.caret{
    width: 0;
    height: 0;
    margin-left: 2px;
    display: inline-block;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.pull-left{
  float: left;
}
.pull-right{
  float: right;
}

/* Ends Basic Styleing
================================ */
