/* Begin header style */

.c-button {
    /*min-width: 120px;*/
}

.c-button,
.c-button.btn,
.btn,
.btn.btn {
    z-index: 2;
    border: none;
    height: 50px;
    outline: none;
    cursor: pointer;
    @include flexbox;
    overflow: hidden;
    background: none;
    text-align: center;
    position: relative;
    white-space: nowrap;
    @include padding(7 25);
    text-transform: uppercase;
    font-size: 1rem !important;
    @include border-radius(0.375rem);
    line-height: 1.5;
    font-family: $secondaryFont;
    @include align-items(center);
    border: 1px solid $transparent;
    @include justify-content(center);
    @include transition(all 0.3s ease);
    font-weight: $weightBold !important;
    
    &:after {
        top: -50px;
        z-index: 1;
        content: "";
        left: -75px;
        width: 50px;
        opacity: 0.2;
        height: 155px;
        background: $white;
        position: absolute;
        @include transform(rotate(35deg));
        @include transition(all 550ms cubic-bezier(0.19, 1, 0.22, 1));
    }

    &:hover {
        @include box-shadow(0 4px 15px 0 rgba($black, 0.25));

        &:after {
            left: 120%;
            @include transition(all 1200ms cubic-bezier(0.19, 1, 0.22, 1));
        }
    }
    
    &-block {
        display: block;
        width: $viewportSize;
    }
    &-inline {
        display: inline-block;
    }
    &-inlineflex {
        @include inline-flex;
    }
    &-rounded {
        @include border-radius(50px);
    }
    &-radius {
        @include border-radius(4px);
    }
    
    &-w190 {
        min-width: 190px;
        max-width: 190px;
    }
    
    &-dashed {
        border-style: dashed !important;
    }
    &-shadow {
        @include box-shadow(0 2px 4px 0 rgba($black, 0.4));
    }
    /*@extend .swipe;*/
    &-mini {
        height: 30px;
        line-height: 1.35;
        @include padding(6 15);
        font-size: 13px !important;
        letter-spacing: 0px !important;
        text-transform: none !important;
    }
    
    &-xs {
        height: 35px;
        max-height: 35px;
        line-height: 1.35;
        @include padding(6 12);
        font-size: 13px !important;
        &.w70 {
            min-width: 70px;
            max-width: 70px;
            @include padding(6 6);
        }
    }
    &-sm {
        height: 40px;
        line-height: 1.5;
        @include padding(7 20);
        font-size: 13px !important;
        &-h35 {
            height: 35px !important;
            max-height: 35px !important;
        }
    }
    &-md {
        height: 48px;
        @include font-size(15);
        line-height: 1.35;
        @include padding(15 18);
    }
    &-lg {
        height: 58px;
        @include font-size(15);
        @include padding(14 20);
        line-height: 1.35;
    }
    &.size--xs {
        @include padding(5 10);
    }
    
    &.has--centered {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    &--primary,
    &-primary {
        color: $white !important;
        border-color: $hoverColor !important;
        background-color: $hoverColor !important;
        
        &:hover, &:active, &:focus {
            color: $white !important;
            border-color: darken($hoverColor, 5%) !important;
            background-color: darken($hoverColor, 5%) !important;
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $hoverColor !important;
            border-color: $border !important;
            background-color: $white !important;
            
            &:hover, &:active, &:focus {
                color: $white !important;
                border-color: $hoverColor !important;
                background-color: $hoverColor !important;
            }
        }
        &-borderedStyle2 {
            color: $dark-gray !important;
            border-style: dashed !important;
            border-color: rgba($primaryColor, 0.4) !important;
            background-color: rgba($white, 0) !important;
            
            &:hover, &:active, &:focus {
                color: $white !important;
                border-color: $hoverColor !important;
                background-color: $hoverColor !important;
            }
        }
        &-gradient {
            color: $white;
            border: 0px none;
            @include transition(all 0.3s ease);
            @include linear-gradient(to right, $primary, $subprimary);
            
            &:hover, &:active, &:focus {
                color: $white;
                border: 0px none;
                position: relative;
                @include transform(translateY(-5px));
                @include linear-gradient(to right, $primary, $subprimary);
            }
        }
    }
    
    &--blue {
        color: $white;
        border-color: $blue;
        background-color: $blue;
        
        &:hover, &:active, &:focus {
            color: $white;
            border-color: $blue_sh1;
            background-color: $blue_sh1;
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $blue;
            border-color: $black_sh3;
            background-color: $white;
            
            &:hover, &:active, &:focus {
                color: $white;
                border-color: $blue_sh1;
                background-color: $blue_sh1;
            }
        }
    }
    
    &--info {
        color: $white;
        border-color: $info;
        background-color: $info;
        
        &:hover, &:active, &:focus {
            color: $white;
            border-color: darken($info, 10%);
            background-color: darken($info, 10%);
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $blue_sh4;
            background-color: $blue_sh6;
            border: solid 1px rgba(18, 82, 245, 0.25);
            
            &:hover, &:active, &:focus {
                color: $white;
                border-color: darken($info, 10%);
                background-color: darken($info, 10%);
            }
        }
    }
    
    &--red {
        color: $white;
        border-color: $error;
        background-color: $error;
        
        &:hover, &:active, &:focus {
            color: $white;
            border-color: $blackred;
            background-color: $blackred;
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $error;
            border-color: $error;
            background-color: $white;
            
            &:hover, &:active, &:focus {
                color: $white;
                border-color: $blackred;
                background-color: $blackred;
            }
        }
    }
    
    &--white {
        color: $primary;
        border-color: $white;
        background-color: $white;
        
        &:hover, &:active, &:focus {
            color: $primary;
            border-color: $offwhite;
            background-color: $offwhite;
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $white;
            border-color: $white;
            background-color: $transparent;
            
            &:hover, &:active, &:focus {
                color: $white;
                border-color: $white;
                background-color: $transparent;
            }
        }
        &:disabled,
        &[disabled] {
            @include opacity(0.5);
            pointer-events: none;
        }
    }
    
    &--black {
        color: $white;
        border-color: $black;
        background-color: $black;
    
        &:hover, &:active, &:focus {
            color: $white;
            border-color: darken($black, 10%);
            background-color: darken($black, 10%);
            @include transform(translateY(-5px));
        }
        &-bordered {
            color: $black;
            border-color: $black;
            background-color: $white;
            
            &:hover, &:active, &:focus {
                color: $white;
                border-color: $black;
                background-color: $black;
            }
        }
    }
    
    &--boderwidth1 {
        border-width: 1px;
    }
    + .c-button {
        @include margin-left(10);
    }
    &-disabled {
        @include opacity(0.5);
        pointer-events: none;
    }
    &-text {
        height: auto;
        border: 0px none;
        text-align: left;
        min-width: inherit;
        @include padding(0);
        letter-spacing: 0.5px;
        @include font-size(16);
        color: rgba($white, 0.8);
        font-weight: $weightBold;
        text-transform: capitalize;
        background-color: transparent;
        @include transition(all 0.3s ease);
        border-bottom: 1px solid rgba($white, 0.8);
        
        &:hover, &:focus, &:active {
            color: rgba($white, 1);
            border-bottom: 1px solid rgba($white, 0);
        }
        &--blue {
            color: $blue_sh1;
        }
    }
    
    &-link-muted {
        color: rgba($white, 0.4);
        @include font-size(14);
        line-height: 1.4;
        @include transition(all 0.3s ease);
        svg,
        .img_svg {
            color: rgba($white, 0.4);
            @include transition(all 0.3s ease);
        }
        
        &:hover, &:focus, &:active {
            color: $white;
            @include opacity(1);
            svg,
            .img_svg {
                color: $white;
            }
        }
    }
    
    &:disabled {
        @include opacity(0.5);
        pointer-events: none;
    }
    .text {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    &--tertiary {
        color: $border;
        border: 0px none;
        min-width: inherit;
        @include padding(0);
        @include font-size(15);
        background-color: transparent;
        
        &:hover, &:active, &:focus {
            border: 0px none;
            @include padding(0);
            color: rgba($white, 0.75);
            background-color: transparent;
        }
        &:after {
            display: none !important;
        }
    }
    .gl-icon {
        @include size(24px);
        display: inline-block;
        vertical-align: middle;
    }
    .gl-cta__icon {
        flex-shrink: 0;
    }
    &.has-icon--after {
        text-align: left;
        @include inline-flex;
        @include align-items(center);
        @include justify-content(space-between);
        .owl-icon {
            @include inline-flex;
            vertical-align: middle;
            @include margin-top(-2);
            @include margin-left(4);
        }
        .icon {
            @include margin-left(4);
        }
    }
    &.has-icon--before {
        text-align: center;
        @include inline-flex;
        @include align-items(center);
        @include justify-content(center);
        .owl-icon {
            @include inline-flex;
            vertical-align: middle;
            @include margin-top(-2);
            @include margin-right(4);
        }
        .icon {
            @include margin-right(4);
        }
        
        &:hover, &:active, &:focus {
            .icon-arrow-right {
                &.grey {
                    @include opacity(1);
                }
            }
        }
        .gl-cta__icon {
            text-align: right;
            @include margin-right(30);
        }
    }
    &--Back {
        height: auto;
        @include padding(0);
        @include margin(14 0);
        background-color: transparent;
        
        > i {
            position: relative;
            @include padding(4);
            border-style: solid;
            display: inline-block;
            vertical-align: middle;
            @include margin-top(-1.25);
            border-width: 0 3px 3px 0;
            border-color: $primaryColor;
            @include transition(all 0.3s ease);
            
            &.right {
                @include transform(rotate(-45deg));
            }
            &.left {
                @include transform(rotate(135deg));
            }
            &.up {
                @include transform(rotate(-135deg));
            }
            &.down {
                @include transform(rotate(45deg));
            }
        }
        &.btn {
            height: auto;
            border: 0px none;
            color: $primaryColor;
            @include padding(0 4);
            @include font-size(16);
            background-color: transparent;
            &.fs--16 {
                font-size: 16px !important;
            }
            &:hover, &:active, &:focus {
                color: $primary !important;
                background-color: transparent !important;
                > i {
                    border-color: $primary;
                }
            }
        }
        
        &.text--black {
            color: $black;
            font-weight: $weightRegular !important;
            
            > i {
                border-color: $black;
            }
        }
    }
    
    &--absolute {
        top: 50%;
        right: 0;
        width: auto;
        height: 100%;
        max-height: 40px;
        min-width: inherit;
        position: absolute;
        @include padding-left(15);
        @include padding-right(15);
        @include transform(translateY(-50%));
    }
    
    &.inlineloading {
        padding-left: 40px !important;
    }
}

.c-button-transparent {
    color: $black_sh3;
    cursor: pointer;
    border: 0px none;
    @include opacity(1);
    @include font-size(15);
    background-color: transparent;
    @include flexbox();
    text-align: center;
    @include align-items(center);
    .icon {
        color: $black_sh3;
        @include size(18px);
        @include opacity(1);
        text-align: center;
        @include font-size(14);
        line-height: 25px;
        @include margin-right(5);
    }
    &:last-child {
        .icon {
            @include margin-left(5);
            @include margin-right(0);
        }
    }
    &:first-child {
        .icon {
            @include margin-left(0);
            @include margin-right(5);
        }
    }
    &:hover, &:active, &:focus {
        color: $red;
        @include opacity(1);
        .icon {
            color: $red;
        }
    }
    &.selected {
        .icon {
            color: $red;
            @include opacity(1);
        }
    }
}

.button-groups {
    .c-button {
        + .c-button {
            @include margin-left(10);
        }
    }
    &.block-button {
        @media (max-width: 767px) {
            @include flex-direction(column, vertical);
            .c-button {
                + .c-button {
                    @include margin-top(10);
                    @include margin-left(0);
                }
            }
        }
    }
}

.btn-group {
    &.bordered {
        > .btn {
            font-size: 15px;
            font-style: normal;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: -0.5px;
            line-height: 1.25 !important;
            color: $primaryColor !important;
            padding: 0.3125rem 0.9375rem !important;
            background-color: transparent !important;
            
            &.is--active {
                color: $white !important;
                background-color: $primaryColor !important;
            }
        }
    }
    
    &-sm {
        max-height: 30px;
        > .btn {
            border-radius: 6px;
            height: 30px !important;
            line-height: 1.25 !important;
            padding: 0.3125rem 0.9375rem !important;
        }
    }
}

.btn {
    &.button--more {
        padding: 0px;
        max-width: 30px;
        max-height: 30px;
        margin-right: -10px;
    }
    &-link {
        color: $gray-500 !important;
        
        &:hover {
            color: $primaryColor !important;
        }
    }
}

/* End header style */
