/* Begin Navigation style(s) */

.c-navigation {
  $navigationSelf: &;

  height: 36px;
  @include flexbox;
  text-align: center;
  @include margin-bottom(0);
  @include align-items(center);

  @include responsive(md) {
    height: 36px;
    @include flexbox;
  }

  &--list {
    text-align: center;
    @include margin(0);
    @include padding(0);
    @include align-items(center);

    li,
    .nav-item {
      position: relative;
      text-align: center;
      display: inline-block;
      @include margin(0 15);

      > a {
        display: block;
        cursor: pointer;
        text-shadow: none;
        color: $menu-color;
        @include font-size(15);
        line-height: 19px;
        @include padding(9 0 7);
        font-weight: $weightBold;
        font-family: $primaryFont;
        text-transform: uppercase;
        @include transition(all 0.3s ease);

        &:hover {
          color: $brand;
        }

        &.is--active {
          color: $brand;
          font-weight: $weightBold;
        }

        &.has-child {
          &:after {
            @extend .drop-arrow;
            content: '\f107';
          }

          &.active {
            &:after {
              color: $brand;
            }
          }
        }

        &.is--active {
          color: $brand;
          font-weight: $weightBold;

          &:before {
            bottom: 0px;
            width: 100%;
            height: 3px;
            content: ' ';
            position: absolute;
            background-color: $brand;
          }
        }
      }

      .sub-menu {
        left: 0px;
        top: 85px;
        z-index: 19;
        margin: 0px;
        width: 190px;
        padding: 0px;
        text-align: left;
        visibility: hidden;
        position: absolute;
        @include opacity(0);
        background: $white none repeat scroll 0 0;
        @include transition(all 0.3s linear 0.15s);
        @include border-radius(0 0 4px 4px!important);
        @include box-shadow(0 3px 6px 0 rgba($black, 0.25) !important);

        &.right {
          left: auto;
          right: -14px;
        }

        > li {
          margin: 0;
          height: auto;
          display: block;
          padding: 0px 0px;
          text-align: left;
          background-color: $transparent;

          a {
            display: block;
            color: $greyish;
            text-align: left;
            position: relative;
            @include font-size(15);
            @include padding(12 20);
            font-weight: $weightRegular;
            text-transform: capitalize;
            @include transition(all 0.3s ease);
            background: $white none repeat scroll 0 0;

            &:hover,
            &.active {
              color: $blue_sh1;
              @include padding(12 20);
              font-weight: $weightRegular;
              border: 0px none !important;
              background: rgba($blue_sh1, 0.1) none repeat scroll 0 0;
            }

            &.has-child {
              &:after {
                @extend .drop-arrow;
                content: '\f105';
                float: right;
                top: 3px !important;
                color: $black !important;
              }
            }

            &:hover,
            &.active {
              &.has-child {
                &:after {
                  color: $blue_sh1 !important;
                }
              }
            }
          }
          &:hover {
            > a {
              &.has-child {
                &:after {
                  color: $blue_sh1 !important;
                }
              }
              color: $blue_sh1;
              background: rgba($blue_sh1, 0.1) none repeat scroll 0 0;
            }
          }

          &:last-child {
            @include border-radius(0 0 4px 4px!important);
            a {
              @include border-radius(0 0 4px 4px!important);
            }
          }

          &:hover .sub-menu {
            top: 0;
          }
        }

        .sub-menu {
          top: 85px;
          left: 100%;
        }

        .sub-menu {
          @include border-radius(4px!important);

          > li {
            &:first-child {
              @include border-radius(4px 4px 0 0!important);
              a {
                @include border-radius(4px 4px 0 0!important);
              }
            }
          }
        }
      }

      &:hover > .sub-menu {
        top: 55px;
        @include opacity(1);
        visibility: visible;
      }
    }
  }

  &__icons {
    left: 15px;
    right: 15px;
    position: absolute;
    @include flexbox();
    width: calc(100% - 30px);
    @include align-items(center);

    &--search {
      color: $white;
      cursor: pointer;
      border: 0px none;
      @include padding(0);
      @include size(30px);
      @include font-size(18);
      background: $transparent;
      @include transition(all 0.3s ease);

      left: auto;
      right: 0px;
      position: absolute;

      svg {
        @include size(30px);
        @include font-size(18);
      }

      &:hover {
        color: $blue_sh1;
      }
    }
  }

  @include responsive(md) {
    .c-navigation {
      &--list {
        width: 100%;
        @include flexbox();
        text-align: center;
        display: inline-block;
      }

      &__icons {
        &--navicon {
          display: none;
        }
      }

      &__icons {
        left: auto;
        width: auto;
        right: 35px;

        &--search {
          color: $white;

          &:hover {
            color: $blue_sh1;
          }
        }

        &--navicon {
          display: none;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  @include responsive(md) {
    #{$navigationSelf}--list {
      li {
        @include margin(0 3);

        a {
          @include font-size(16);
          line-height: 31px;
          @include padding(12 20);
        }
      }
    }

    @include respond-to(lg) {
      #{$navigationSelf}--list {
        li {
          @include margin(0 6);

          a {
            @include font-size(16);
            line-height: 31px;
            @include padding(12 20);
          }
        }
      }
    }
  }
}

.visible-xs-device {
  display: none;
}

body.noscroll {
  overflow: hidden !important;
  .c-responsive-standalone--heading .c-header--logo--mobile {
    z-index: 500;
    position: relative;
  }
}

@media (max-width: 767px) {
  body.noscroll {
    overflow: hidden !important;
  }
  .hidden-xs-device {
    display: none;
  }
  .visible-xs-device {
    display: block;
  }
  .c-header--logo--mobile {
    display: block;
    max-width: 76px;
  }
  .c-header__additional--btnGroup--mobile {
    @include flexbox;
    @include flex-direction(row, horizontal);
  }

  .c-navigation .c-hamMenu {
    display: block;
  }
}

/* End Navigation style(s) */
