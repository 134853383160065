/* Begin Mobile Menu style(s) */

.navSidebar {
  top: 0;
  padding: 0;
  right: auto;
  left: -240px;
  height: 100%;
  width: 240px;
  z-index: 1032;
  display: block;
  position: fixed;
  overflow: hidden;
  visibility: hidden;
  overflow-y: visible;
  background-color: $brand;
  @include transition(all 0.3s ease);
  @include transform(translate3d(100%, 0, 0));
  @include transform(translateZ(0));
  @include transform(translateX(0));
}

.nav-open {
  body {
    background-color: $white;
  }
  .main-panel {
    overflow: hidden;
  }
}

.widget {
  &__menuArea {
    @include flexbox;
    position: relative;
    @include padding(0 0 5);
    @include flex(1 1 auto);
    height: calc(100% - 15px);
    @include align-items(stretch);
    @include flex-direction(column, vertical);
    @include transition(opacity 0.1s ease 0.25s);

    &__content {
      @include flexbox;
      @include flex(1 1 auto);
      @include align-items(stretch);
      @include justify-content(space-between);
      @include flex-direction(column, vertical);

      &--body {
        overflow: hidden;
        position: relative;
        @include size(100%);
        @include flex(1 1 auto);

        &--scroll {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: auto;
          overflow-x: hidden;
          position: absolute;
          @include padding-top(10);
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }
}

.responsive-navigation {
  width: 100%;
  @include margin-bottom(10);

  .nav {
    $navSelf: &;
  }

  &__list {
    &.nav {
      @include flexbox;
      @include padding(0);
      @include flex-direction(column, vertical);
    }
  }

  &__item {
    border-bottom: 1px solid rgba($white, 0.1);

    &.nav-item {
      border-bottom: 1px solid rgba($white, 0.1);
    }
  }
  .nav-link {
    color: $white;
    cursor: pointer;
    @include flexbox;
    position: relative;
    @include font-size(15);
    @include padding(22 20);
    font-weight: $weightBold;
    text-transform: uppercase;
    @include align-self(center);
    @include align-items(center);
    @include align-content(center);
    @include justify-content(flex-start);
    @include transition(all 0.4s ease 0.25s);

    &:hover,
    &:focus,
    &:active {
      color: rgba($footer-color, 0.95);
    }

    &.is--active {
      color: $white;
      background-color: $footer-color;
    }
  }
}

/* End Mobile Menu style(s) */
