/* Begin Custom Dropdown style(s) */

.smallcaps {
  text-transform: capitalize !important;
}

.main-page {
  @include padding-top(90);

  @include breakpoint(991) {
    @include padding-top(90);
  }
}

[class^='section--'] {
  position: relative;

  &[data-bg='purple'],
  &[data-bg='white'],
  &[data-bg='gray'],
  &[data-bg='blue'],
  &[data-bg='black'] {
    &:before {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: ' ';
      position: absolute;
      pointer-events: none;
    }
  }
  &[data-bg='blue'] {
    &:before {
      @include linear-gradient(to top, $brand, $brand);
    }
  }

  &[data-bg='gray'] {
    color: $grey;
    &:before {
      background-color: $white_sh2;
    }
  }

  &[data-bg='black'] {
    color: $white;
    background-color: $gray-900;
  }
}

.wrapper-full-page {
  &[data-bg='white'] {
    &:before {
      top: 0;
      left: 0;
      z-index: -1;
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background-image: url('../../../images/header-bg.svg');
      background-repeat: no-repeat;
      background-position: -275px -230px;
      background-size: cover auto;
    }
  }
}

.section--main {
  @include padding(50 0 0);

  @include responsive(sm) {
    @include padding(60 0 0);

    @include respond-to(md) {
      @include padding(75 0 0);

      @include respond-to(lg) {
        @include padding(75 0 0);
      }
    }
  }
  .banner--content {
    @include margin-top(-25);
    @include margin-bottom(50);

    @include responsive(sm) {
      @include margin-top(-50);
      @include margin-bottom(0);

      @include respond-to(md) {
        @include margin-top(-100);

        @include respond-to(lg) {
          @include margin-top(-150);
        }
      }
    }
    .title {
      color: $brand;
      margin-bottom: 0;
      @include font-size(40);
      line-height: 1;
      font-weight: $weightUltrabold;

      @include responsive(sm) {
        @include font-size(50);
        line-height: 1;

        @include respond-to(md) {
          @include font-size(65);
          line-height: 1;

          @include respond-to(lg) {
            @include font-size(80);
            line-height: 1;
          }
        }
      }
    }

    .fs--20 {
      line-height: 1.5 !important;
      @include minbreakpoint(1024) {
        max-width: 85%;
      }
    }
  }
}

.fs--20 {
  @extend .fs--17;
  line-height: 1.65;
  color: $menu-color;

  @include responsive(sm) {
    font-size: 1.125rem;

    @include respond-to(md) {
      font-size: 1.188rem;

      @include respond-to(md) {
        font-size: 1.25rem;
      }
    }
  }
}

.equal--width {
  @include margin-bottom(35);

  @include responsive(sm) {
    @include margin-bottom(35);

    @include respond-to(md) {
      @include margin-bottom(50);
    }
  }

  .c-button {
    width: 190px;
  }

  @include breakpoint(580) {
    .c-button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      @include margin-bottom(15);
    }
  }
}

.embed-responsive {
  width: 100%;
  height: auto;
  color: #aeaeae;
  @include flexbox;
  background-color: $white;
  @include margin-bottom(-60);
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(0.375rem);
  @include box-shadow(0 4px 15px 0 rgba(151, 151, 151, 0.25));

  @include responsive(sm) {
    @include margin-bottom(-60);

    @include respond-to(md) {
      @include margin-bottom(-90);

      @include respond-to(lg) {
        @include margin-bottom(-120);
      }
    }
  }
}

.section--gray {
  position: relative;
  @include padding(125 0 25);

  @include responsive(sm) {
    @include padding(140 0 25);

    @include respond-to(md) {
      @include padding(160 0 60);

      @include respond-to(lg) {
        @include padding(180 0 60);
      }
    }
  }

  .fs--17 {
    @extend .fs--17;
    line-height: 1.61;
    color: $text-color;
  }
}

.section--features,
.section--compliance {
  p {
    @extend .fs--17;
    line-height: 1.9;
    max-width: 95%;

    strong {
      font-weight: $weightBold;
    }
  }

  .features--tags {
    width: 100%;
    @include flexbox;
    @include flex-wrap(wrap);
    @include padding(25 0 13);
    @include align-items(center);

    @include responsive(sm) {
      @include padding(25 0 13);

      @include respond-to(md) {
        @include padding(25 0 13);

        @include respond-to(lg) {
          @include padding(35 0 23);
        }
      }
    }

    &__items {
      @include flexbox;
      @include margin-right(12);
      @include margin-bottom(12);
    }

    &__link {
      color: $black;
      @extend .fs--17;
      max-height: 50px;
      line-height: 1.22;
      font-weight: $weightBold;
      background-color: $white;
      @include border-radius(100px);
      @include transition(all 0.3s ease);

      @include breakpoint(767) {
        font-size: 1rem;
      }
    }
  }
}

.section--contact,
.section--services,
.section--portfolio,
.section--clients,
.section--technology {
  color: $grey;
  position: relative;
  @include padding(70 0);

  @include responsive(sm) {
    @include padding(80 0);

    @include respond-to(md) {
      @include padding(90 0);

      @include respond-to(lg) {
        @include padding(100 0);
      }
    }
  }
}

.section--services {
  @include padding-bottom(20);
  @include responsive(sm) {
    @include padding-bottom(20);

    @include respond-to(md) {
      @include padding-bottom(30);

      @include respond-to(lg) {
        @include padding-bottom(40);
      }
    }
  }

  .services--icon {
    z-index: 2;
    display: block;
    margin: 0 auto;
    text-align: center;
    position: relative;
    @include size(90px, 90px);
    @include margin-bottom(20);

    &:before {
      top: 10px;
      z-index: -1;
      right: -30px;
      content: ' ';
      position: absolute;
      pointer-events: none;
      @include size(70px, 70px);
      @include border-radius(10px);
      @include transform(rotate(35deg));
      @include transition(all 0.3s ease);
      /*background-color: rgba($primary_sh2, 0.1);
      @include animation(load-spin 5s infinite linear);*/
      @include linear-gradient(to top, rgba($primary_sh2, 0.01), rgba($primary_sh2, 0.1));
    }
  }

  .services--block {
    h3 {
      color: $menu-color;
      @include font-size(22);
      line-break: 1.25;
      @include margin-bottom(15);
      font-weight: $weightUltrabold;

      @include breakpoint(767) {
        @include font-size(20);
        line-break: 1.25;
      }
    }

    p {
      color: $text-color;
      @include font-size(16);
      line-break: 1.5;
      @include margin-bottom(15);

      @include breakpoint(767) {
        @include font-size(15);
        line-break: 1.5;
      }

      &:last-child {
        @include margin-bottom(0);
      }
    }
  }
}

.section--webSolutions {
  color: $grey;
  position: relative;
  @include padding(55 0);

  @include responsive(sm) {
    @include padding(65 0);

    @include respond-to(md) {
      @include padding(75 0);

      @include respond-to(lg) {
        @include padding(90 0);
      }
    }
  }

  &__inner {
    @include padding-top(40);

    @include responsive(sm) {
      @include padding-top(50);

      @include respond-to(md) {
        @include padding-top(60);
        @include padding-left(35);

        @include respond-to(lg) {
          @include padding-top(70);
          @include padding-left(50);
        }
      }
    }
  }

  .fs--17,
  li {
    @extend .fs--17;
    line-height: 1.5;
    color: $text-color;
  }

  .size--80 {
    display: block;
    @include size(80px);
    @include margin-bottom(10);
  }

  .webSolutions--blocks {
    width: 100%;
    @extend .fs--17;
    line-height: 1.5;
    color: $text-color;

    h4 {
      color: $black;
      @include font-size(22);
      line-height: 1.5;
      font-weight: $weightSemibold;
    }
  }

  .features--tags {
    @include padding-top(15);
    @include padding-bottom(0);
    @include justify-content(center);

    &__items {
      @include margin-bottom(23);
    }

    &__link {
      color: $subprimary;
      @include padding(10 35);
      border: 1px solid $primaryColor;

      &:hover {
        color: $white;
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
      }
    }
  }
}

.styled-bullet {
  list-style: none;
  @include padding-left(18);
  li {
    list-style-type: disc;
  }
}

.react-parallax {
  &-bgimage {
    @include object-fit(cover);
    @include object-position(center center !important);
  }
}

.section--mission {
  @include padding(40 0);

  @include responsive(sm) {
    @include padding(50 0);

    @include respond-to(md) {
      @include padding(60 0);

      @include respond-to(lg) {
        @include padding(70 0);
      }
    }
  }
  .page-head {
    .u-heading--exlarge {
      color: $white;
    }
  }
  .quote {
    @include font-size(17);
    line-height: 1.65;

    @include responsive(sm) {
      @include font-size(18);
      line-height: 1.65;

      @include respond-to(md) {
        @include font-size(19);
        line-height: 1.65;

        @include respond-to(lg) {
          @include font-size(20);
          line-height: 1.65;
        }
      }
    }
  }
}

.section--portfolio {
  .page-head {
    .u-heading--large {
      color: $black;
      text-transform: none;
    }
  }

  .overlay {
    top: 0;
    left: 0;
    color: $white;
    position: absolute;
    @include opacity(0);
    visibility: hidden;
    @include font-size(16);
    @include size(100%, 100%);
    @include transform(scale(2));
    @include transition(all 0.3s ease-out 0s);

    background: -webkit-linear-gradient(
      to right,
      rgba(0, 131, 176, 0.7),
      rgba(0, 180, 219, 0.7)
    );
    background: -moz-linear-gradient(
      to right,
      rgba(0, 131, 176, 0.7),
      rgba(0, 180, 219, 0.7)
    );
    background: -o-linear-gradient(
      to right,
      rgba(0, 131, 176, 0.7),
      rgba(0, 180, 219, 0.7)
    );
    background: -ms-linear-gradient(
      to right,
      rgba(0, 131, 176, 0.7),
      rgba(0, 180, 219, 0.7)
    );
    background: linear-gradient(to right, rgba(0, 131, 176, 0.7), rgba(0, 180, 219, 0.7));
  }

  .info--content {
    left: 20px;
    bottom: 20px;
    text-align: left;
    position: absolute;
    @include flexbox;
    @include flex-wrap(wrap);
    @include flex-direction(column, vertical);

    a {
      display: table;
      color: #1f1f1f;
      background: $white;
      @include padding(8 12);
      text-transform: uppercase;
    }
    h4 {
      color: #1f1f1f;
      @include font-size(15);
      line-height: 1.4;
      position: relative;
      font-weight: 400;
      letter-spacing: 0.3px;
    }
    p {
      display: table;
      @include font-size(15);
      font-weight: 400;
      font-style: italic;
      background: $white;
      color: #6f6f6f;
      @include padding(8 12);
      @include margin-top(7);
    }
  }
  .know-more {
    right: 20px;
    bottom: 20px;
    text-align: left;
    position: absolute;
    @include font-size(30);

    button {
      color: $white;
      display: block;
      @include size(36px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23fff'%3E%3Cpath d='M8 176a8 8 0 008-8V16h152a8 8 0 000-16H8a8 8 0 00-8 8v160a8 8 0 008 8zM168 496H16V344a8 8 0 00-16 0v160a8 8 0 008 8h160a8 8 0 000-16zM504 336a8 8 0 00-8 8v152H344a8 8 0 000 16h160a8 8 0 008-8V344a8 8 0 00-8-8zM504 0H344a8 8 0 000 16h152v152a8 8 0 0016 0V8a8 8 0 00-8-8zM328 248h-64v-64a8 8 0 00-16 0v64h-64a8 8 0 000 16h64v64a8 8 0 0016 0v-64h64a8 8 0 000-16z'/%3E%3C/svg%3E");
      background-size: 36px auto;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .my-masonry-grid {
    width: auto;
    @include flexbox;
    @include margin(0);
    @include padding(0);

    &_column {
      @include margin(0);
      @include padding(0);
      background-clip: padding-box;

      > div {
        overflow: hidden;
        @include margin(5);
        position: relative;
        @include padding(0);
        @include font-size(0);
        @include margin-bottom(10);
        border: 1px solid rgba($border, 0.5);
        @include box-shadow(0px 3px 10px #080a251a);

        &:hover {
          .overlay {
            @include opacity(1);
            visibility: visible;
            @include transform(scale(1));
          }
        }
      }
    }
  }
}

.s--title {
  width: 100%;
  text-align: center;
  position: relative;

  .page-head--divider {
    left: 50%;
    top: 90px;
    position: absolute;
    @include transform(translateX(-50%));
  }

  span {
    left: 0;
    right: 0;
    top: -35px;
    width: 100%;
    margin: 0 auto;
    color: #e6e6e6;
    font-size: 220px;
    line-height: 150px;
    position: absolute;
    white-space: nowrap;
    pointer-events: none;
    @include opacity(0.35);
    font-weight: $weightUltrabold;

    @include breakpoint(767) {
      left: 50%;
      font-size: 100px;
      line-height: 70px;
      text-align: center;
      @include transform(translateX(-50%));
    }
  }
  h5 {
    font-size: 20px;
    color: #1c1c1c;
    margin-bottom: 15px;
    font-weight: $weightLight;
    text-transform: uppercase;
  }
  h4 {
    color: $black;
    font-size: 50px;
    line-height: 45px;
    position: relative;
    @include padding-bottom(30);
    @include margin-bottom(75);
    font-weight: $weightUltrabold;
  }
}

.blur {
  -webkit-filter: blur(20px);
  filter: blur(20px);
  transition: filter 600ms, -webkit-filter 600ms;

  &.lazy-load-image-loaded {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}

@include breakpoint(767) {
  .m-mb-3 {
    @include margin-bottom(30);
  }
  .m-mb-4 {
    @include margin-bottom(40);
  }
  .m-mb-5 {
    @include margin-bottom(50);
  }
  .col--half {
    max-width: 50%;
    @include flex(0 0 50%);
  }
  .img-responsive {
    width: 100%;
  }
}

.section--contact {
  form {
    @include minbreakpoint(1200) {
      @include padding-left(50);
      @include padding-right(50);
    }
  }
}

.section--clients,
.section--technology {
  border-top: 1px solid rgba($border, 0.25);
  @include breakpoint(767) {
    @include padding-bottom(30);
  }

  .client--lists,
  .technology--lists {
    @include flexbox;
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(center);
    &-items {
      width: 100%;
      max-width: 20%;
      @include padding-left(20);
      @include padding-right(20);

      @include breakpoint(440) {
        width: 100%;
        max-width: 100%;
        @include padding-left(0);
        @include padding-right(0);
        @include margin-bottom(30);
      }

      @media screen and (min-width: 441px) and (max-width: 767px) {
        width: 100%;
        max-width: 50%;
        @include margin-bottom(30);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .image-block-wrapper {
      overflow: hidden;
      position: relative;
      padding-bottom: 35%;

      > span {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        img {
          @include object-fit(cover);
          @include border-radius(4px);
          object-position: center;
        }
      }
    }
  }
}
.section--technology {
  .technology--lists {
    &-items {
      @include margin-bottom(0);
      @include padding-left(30);
      @include padding-right(30);
      border-right: 1px solid $border;
      border-bottom: 1px solid $border;

      &:nth-child(5n + 5) {
        border-right: 1px solid $white;
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        border-bottom: 1px solid $white;
      }

      @include breakpoint(580) {
        width: 50%;
        max-width: 50%;
        @include padding-left(15);
        @include padding-right(15);
        @include margin-bottom(0);

        &:nth-child(2n + 2) {
          border-right: 1px solid $white;
        }
        &:nth-child(5) {
          border-right: 1px solid $border;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          border-bottom: 1px solid $border;
        }
      }
      @media screen and (min-width: 581px) and (max-width: 767px) {
        width: 33.3333%;
        max-width: 33.3333%;
        @include padding-left(20);
        @include padding-right(20);
        @include margin-bottom(0);

        &:nth-child(3n + 3) {
          border-right: 1px solid $white;
        }
        &:nth-child(5) {
          border-right: 1px solid $border;
        }
        &:last-child {
          border-right: 1px solid $white;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          border-bottom: 1px solid $border;
        }
      }
    }

    .image-block-wrapper {
      padding-bottom: 100%;
      > span {
        top: 50%;
        height: auto;
        @include transform(translateY(-50%));

        img {
          max-width: 80%;
        }
      }
    }
  }
}

/* End Custom Dropdown style(s) */
