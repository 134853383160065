/* Begin page head style */

.page-head {
  $pageHeadSelf: &;

  z-index: 1;
  @include flexbox();
  position: relative;
  @include font-size(13);
  line-height: 18px;
  @include margin-bottom(20);
  @include align-items(center);
  @include flex-direction(column, vertical);

  &.noborder {
    border-bottom: 0px none;
    @include padding-bottom(0);
  }

  &.start {
    @include align-items(flex-start);
    @include justify-content(flex-start);
  }

  &.center {
    @include align-items(center);
    @include justify-content(center);
  }

  &.end {
    @include align-items(flex-end);
    @include justify-content(flex-end);
  }

  @include responsive(sm) {
    @include margin-bottom(25);
    @include align-items(flex-start);
    @include flex-direction(column, vertical);

    @include respond-to(md) {
      @include margin-bottom(35);
      @include align-items(flex-start);
      @include flex-direction(column, vertical);

      @include respond-to(lg) {
        @include align-items(center);
        @include flex-direction(column, vertical);
      }
    }
  }

  .fs--17 {
    color: $text-color;
    @include font-size(18);
    line-height: 1.75;
  }

  &.column {
    @include flex-direction(column, vertical);
  }

  &.rows {
    @include flex-direction(row, horizontal);

    &.u-justify-btw {
      @extend .u-justify-btw;
    }
  }

  [class^='u-heading--'] {
    font-family: $primaryFont;
    font-weight: $weightLight;

    strong {
      font-weight: $weightUltrabold;
    }
  }

  &.text-black {
    color: $black;
  }

  &.text-grey {
    color: $text-color;
    font-weight: $weightMedium;
  }

  .fs--18 {
    color: $text-color;
    @include font-size(18);
  }

  &--divider {
    position: relative;
    display: inline-block;
    @include margin-top(15);
    @include size(90px, 5px);
    @include margin-bottom(20);
    @include border-radius(30px);
    background-color: rgba($brand, 0.2);

    &::after {
      left: 0;
      top: -2.7px;
      content: '';
      position: absolute;
      background-color: $brand;
      @include size(10px, 10px);
      @include border-radius(50%);
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-name: MOVE-BG;
      animation-name: MOVE-BG;
    }

    &.light {
      background-color: rgba($white, 1);
      &::after {
        background-color: lighten($brand, 30%);
      }
    }
  }

  .u-heading--exlarge {
    color: $gray-900;
    @include font-size(26);
    line-height: 1;
    font-weight: $weightLight;
    text-transform: uppercase;

    @include responsive(sm) {
      @include font-size(28);

      @include respond-to(md) {
        @include font-size(30);

        @include respond-to(lg) {
          @include font-size(35);
        }
      }
    }
  }

  .u-heading--large {
    color: $white;
    @include font-size(34);
    line-height: 1;
    font-weight: $weightRegular;

    @include responsive(sm) {
      @include font-size(40);

      @include respond-to(md) {
        @include font-size(44);

        @include respond-to(lg) {
          @include font-size(50);
        }
      }
    }
  }

  .u-heading--medium {
    color: $gray-900;
    @include font-size(26);
    line-height: 1;
    font-weight: $weightUltabold;

    @include responsive(sm) {
      @include font-size(28);

      @include respond-to(md) {
        @include font-size(32);

        @include respond-to(lg) {
          @include font-size(35);
        }
      }
    }
  }

  .u-heading--small {
    color: $gray-900;
    @include font-size(20);
    line-height: 1;
    font-weight: $weightBold;

    @include responsive(sm) {
      @include font-size(22);
    }
  }

  .u-heading--xsmall {
    @extend .ls-10;
    color: $gray-900;
    @extend .fs--17;
    line-height: 1;
    font-weight: $weightBold;

    @include responsive(sm) {
      @include font-size(18);
    }
  }

  &--actions {
    @include flexbox();
    @include padding(0);
    @include font-size(15);
    @include margin-top(0);
    margin-left: 0px !important;

    @include responsive(sm) {
      @include margin(0);
    }
  }
}

.sectionTitle-wrapper {
  text-align: left;
  @include margin-bottom(20);
  @include padding-bottom(0);
  border-bottom: 2px solid rgba($border, 0.5);

  &.noBorder {
    border-bottom: 0px none;
  }

  @include responsive(xs) {
    @include margin-bottom(40);
  }

  .heading--title {
    color: $black_sh1;
    position: relative;
    text-align: center;
    @include font-size(26);
    line-height: 1;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    @include margin-bottom(10);
    font-weight: $weightUltabold;

    @include responsive(xs) {
      @include font-size(34);
    }

    &--medium {
      color: $black_sh1;
      @include font-size(22);
      letter-spacing: 0.035em;
      text-transform: uppercase;
      font-weight: $weightUltabold;
      margin-bottom: -2px;
      display: inline-block;
      @include padding-bottom(10);
      border-bottom: 2px solid $green;

      @include responsive(xs) {
        @include font-size(26);
      }
    }

    &--semiMedium {
      color: $black_sh1;
      @include font-size(22);
      letter-spacing: 0.035em;
      font-weight: $weightUltabold;

      @include responsive(xs) {
        @include font-size(26);
      }
    }

    &--small {
      color: $white;
      @include padding(10 15);
      @include font-size(18);
      letter-spacing: 0.035em;
      font-weight: $weightUltabold;
      margin-bottom: -2px;
      display: inline-block;
      background-color: $green;
      border-bottom: 2px solid darken($green, 10%);

      @include responsive(xs) {
        @include font-size(20);
      }
    }
  }

  .heading--description {
    color: $greyish;
    @include font-size(16);
    line-height: 1;
    font-weight: $weightRegular;

    @include responsive(xs) {
      @include font-size(18);
    }
  }
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@-webkit-keyframes MOVE-BG {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}
@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

/* End page head style */
