/* Begin Footer style(s) */
.c-footer {
  $footerSelf: &;

  width: 100%;
  text-align: left;
  @include font-size(15);
  line-height: 1.25;
  color: rgba($white, 1);
  @include padding(0 0 25);
  background-color: $footer-color;

  @include responsive(sm) {
    @include padding(0 0 25);

    @include respond-to(md) {
      text-align: left;
      @include padding(0 0 25);
    }
  }

  @include breakpoint(767) {
    font-size: 0.938rem;
  }

  > .container {
    @include padding-top(50);
  }

  a {
    color: $white;
    cursor: pointer;
    @include margin-left(20);
    font-weight: $weightMedium;
    @include transition(all 0.3s ease-out 0s);

    &:before {
      content: '/';
      cursor: auto;
      @include margin-right(20);
      pointer-events: none !important;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:hover {
      color: $blue_sh5;
    }

    @include breakpoint(767) {
      font-weight: $weightMedium;
    }
  }

  &--phone,
  &--email,
  &--contact,
  &--hours {
    h4 {
      @include flexbox;
      color: $blue_sh5;
      position: relative;
      @include font-size(20);
      @include margin-bottom(15);
      @include align-items(center);
      font-weight: $weightUltrabold;
      @include justify-content(flex-start);

      @include breakpoint(540) {
        @include font-size(17);
      }

      &::before {
        top: 1px;
        content: ' ';
        position: relative;
        @include size(14px);
        background-color: $white;
        @include margin-right(10);
        @include border-radii(5px, 5px, 5px, 14px);
      }
    }
  }

  &--bottom {
    @include margin-top(35);
    @include padding-top(25);
    border-top: 1px solid $blue_sh6;

    .container {
      @include padding-top(0);
    }
  }

  &--social {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    .MuiSvgIcon-fontSizeLarge {
      font-size: 36px !important;
      @include size(36px !important, 36px !important);
    }

    li {
      @include margin-left(20);
      &:first-child {
        @include margin-left(0);
      }
      > a {
        @include margin-left(0);
      }
    }
  }
}

.scroll--top {
  right: 0.938rem; /* 15px */
  bottom: 5.625rem; /* 90px */
  z-index: 9999;
  position: fixed;
  cursor: pointer;
  display: flex !important;
  @include size(2.5rem); /* 40px */
  background: #e8505b;
  border: 2px solid #e8505b;
  @include border-radius(50%);
  @include align-items(center);
  @include justify-content(center);
  @include transition(all 0.3s ease-out !important);
  box-shadow: 0 20px 15px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);

  &.MuiSvgIcon-root {
    @include size(50px !important);
  }

  i {
    font-size: 22px;
    color: #e8505b;
  }

  &:hover {
    background: #e8505b;

    i {
      color: $white;
    }
  }
}

@include breakpoint(767) {
  .m-text-center {
    text-align: center !important;
  }
}

/* End Footer style(s) */
