/* Begin links view style */
.u-sf {
  font-family: $secondaryFont;
}

.u-pr {
  &--0 {
    padding-right: 0rem !important;
  }
  &--6 {
    padding-right: 0.375rem;
  }
}
.u-pl {
  &--0 {
    padding-left: 0rem !important;
  }
  &--6 {
    padding-left: 0.375rem;
  }
}

.u-pb {
  &--0 {
    padding-bottom: 0rem !important;
  }
  &--6 {
    padding-bottom: 0.375rem;
  }
  &--30 {
    padding-bottom: 1.875rem !important;
    @include responsive(sm) {
      padding-bottom: 1.875rem !important;
    }
  }
  &--50 {
    padding-bottom: 1.25rem !important;
    @include responsive(sm) {
      padding-bottom: 1.875rem !important;
    }
  }
}

.u-pt {
  &--0 {
    padding-top: 0rem !important;
  }
  &--15 {
    padding-top: 0.938rem;
    @include responsive(sm) {
      padding-top: 0.938rem;
    }
  }
  &--25 {
    padding-top: 1.563rem !important;
    @include responsive(sm) {
      padding-top: 1.563rem !important;
    }
  }
  &--30 {
    padding-top: 1.875rem !important;
    @include responsive(sm) {
      padding-top: 1.875rem !important;
    }
  }
  &--50 {
    padding-top: 2.5rem;
    @include responsive(sm) {
      padding-top: 1.563rem;
    }
  }
}

.u-mr {
  &--0 {
    margin-right: 0rem !important;
  }
  &--5 {
    margin-right: 0.313rem !important;
  }
  &--10 {
    margin-right: 0.625rem !important;
  }
  &--15 {
    margin-right: 0.938rem !important;
  }
  &--20 {
    margin-right: 1.25rem !important;
  }
  &--25 {
    margin-right: 1.563rem !important;
  }
}

.u-ml {
  &--0 {
    margin-left: 0rem !important;
  }
  &--5 {
    margin-left: 0.313rem !important;
  }
  &--10 {
    margin-left: 0.625rem !important;
  }
  &--15 {
    margin-left: 0.938rem !important;
  }
  &--20 {
    margin-left: 1.25rem !important;
  }
  &--25 {
    margin-left: 1.563rem !important;
  }
}

/* Top */
.u-mt {
  &--0 {
    margin-top: 0rem !important;
  }
  &--5 {
    margin-top: 0.313rem !important;
  }
  &--10 {
    margin-top: 0.625rem !important;
  }
  &--15 {
    margin-top: 0.938rem !important;
  }
  &--20 {
    margin-top: 1.25rem !important;
  }
  &--25 {
    margin-top: 1.563rem !important;
  }
  &--30 {
    margin-top: 1.875rem;
  }
  &--35 {
    margin-top: 2.188rem;
  }
  &--50 {
    margin-top: 2.5rem;
    @include responsive(sm) {
      margin-top: 3.125rem;
    }
  }
  &--60 {
    margin-top: 2.5rem;
    @include responsive(sm) {
      margin-top: 3.75rem;
    }
  }
}

.u-mb {
  &--0 {
    margin-bottom: 0rem !important;
  }
  &--5 {
    margin-bottom: 0.313rem !important;
  }
  &--10 {
    margin-bottom: 0.625rem !important;
  }
  &--15 {
    margin-bottom: 0.938rem !important;
  }
  &--18 {
    margin-bottom: 1.125rem !important;
  }
  &--20 {
    margin-bottom: 1.25rem !important;
  }
  &--25 {
    margin-bottom: 1.563rem !important;
  }
  &--30 {
    margin-bottom: 1.875rem !important;
  }
  &--35 {
    margin-bottom: 1.875rem !important;
  }
  &--40 {
    margin-bottom: 2.188rem !important; /* 35px */
    @include responsive(sm) {
      margin-bottom: 2.5rem !important; /* 40px */
    }
  }
  &--50 {
    margin-bottom: 2.188rem !important; /* 35px */
    @include responsive(sm) {
      margin-bottom: 3.125rem !important; /* 50px */
    }
  }
  &--60 {
    margin-bottom: 1.875rem !important; /* 30px */
    @include responsive(sm) {
      margin-bottom: 3.75rem !important; /* 60px */
    }
  }
  &--80 {
    margin-bottom: 3.75rem !important; /* 60px */
    @include responsive(sm) {
      margin-bottom: 5rem !important; /* 80px */
    }
  }
  &--150 {
    margin-bottom: 6.25rem !important; /* 100px */
    @include responsive(sm) {
      margin-bottom: 9.375rem !important; /* 130px */
    }
  }
}

.u-border {
  &--0 {
    border: 0px none !important;
  }

  &--btm0 {
    border-bottom: 0px none !important;
  }

  &--top0 {
    border-top: 0px none !important;
  }
}

.u-count {
  color: $black;
  @include opacity(1);
}

.u-svg_icons {
  position: relative;
  @include inline-flex;
  vertical-align: middle;

  &-s20 {
    @include size(22px);
  }

  &-s25 {
    @include size(23px);
  }

  &-leftAlign {
    @include margin-left(3);
  }

  &-rightAlign {
    @include margin-right(3);
  }
}

.u-upper {
  text-transform: uppercase;
}

.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}

.u-image-covered {
  @include image_background();
}
.u-advertisement-section {
  text-align: center;
  &--m80 {
    margin: 80px 0;
  }
}

.u-hr {
  width: 100%;
  margin: 36px auto;
  @include flexbox;

  hr {
    margin: 0px;
    width: 100%;
    min-height: 1px;
    border: 0px none;
    border-top: 1px solid rgba($black, 0.1);
  }
}

.u-price {
  color: $black;
  @include font-size(22);
  line-height: 1.15;
  font-weight: $weightRegular;
  @include margin-bottom(0);
}
.u-bold {
  font-weight: $weightBold;
}

.u-title {
  color: $greyish;
  @include font-size(30);
  font-weight: $weightLight;
  line-height: 1.15;
  letter-spacing: 0px;
}
.u-description {
  color: $greyish;
  @include font-size(15);
  line-height: 1.5;
  letter-spacing: 0px;

  &.u-fs22 {
    @include font-size(19);
    line-height: 1.5;

    @include responsive(sm) {
      @include font-size(22);
      line-height: 1.5;
    }
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
[class^='col-'] {
  padding-right: 15px;
  padding-left: 15px;
}
.container,
.container-fuild {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1040px;
  }
}
.container-fluid {
  @include padding-left(15);
  @include padding-right(15);
}

@include breakpoint(767) {
  .container,
  .container-fuild {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 768px) {
  .d-md-flex {
    @include flexbox;
  }
}
@media (min-width: 992px) {
  .d-lg-flex {
    @include flexbox;
  }
}
@media (min-width: 1200px) {
  .max-1040 {
    max-width: 1040px;
  }
  .d-xl-flex {
    @include flexbox;
  }
}

@media (min-width: 1400px) {
  .container-fuild {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    max-width: 1300px !important;
  }
}

.container {
  &.fullwidth {
    width: 100%;
    max-width: 100% !important;

    &.noGutter {
      padding-left: 0px;
      padding-right: 0px;

      .row {
        margin-left: 0px;
        margin-right: 0px;
      }

      [class^='col-'] {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    &.padd20 {
      padding: 0 20px !important;

      @include breakpoint(767) {
        padding: 0 15px !important;
      }
    }
  }
}

.img-responsive {
  width: 100%;
}

@media (max-width: 767px) {
  .col-mobile-12 {
    max-width: 100%;
    @include flex(0 0 100%);
  }
  .col-mobile-6 {
    max-width: 50%;
    @include flex(0 0 50%);
  }
  .col-mobile-4 {
    max-width: 33.3333%;
    @include flex(0 0 33.3333%);
  }
  .col-mobile-3 {
    max-width: 25%;
    @include flex(0 0 25%);
  }
  .container,
  .container-fuild {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container-fuild,
  .container {
    max-width: 500px;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .container-fuild,
  .container {
    max-width: 700px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container-fuild,
  .container {
    max-width: 800px;
  }
}

.Parallax-BG-Section {
  background-attachment: fixed;
  background-repeat: no-repeat;
  @include background-size(cover);
}
.bgCover {
  background-position: center;
  background-repeat: no-repeat;
  @include background-size(cover);
}

.col-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.x-close {
  top: 50%;
  right: 0;
  z-index: 50;
  border: none;
  cursor: pointer;
  position: absolute;
  @include size(16px);
  background: transparent;
  @include transform(translateY(-50%));
  @include transition(all 0.4s ease-in-out);
  &:before,
  &:after {
    top: 50%;
    left: -15%;
    right: -15%;
    height: 1px;
    content: ' ';
    position: absolute;
    background-color: lighten($black, 20%);
    @include transition(all 0.2s ease-in-out);
  }
  &:before {
    @include transform(rotate(45deg));
  }
  &:after {
    @include transform(rotate(-45deg));
  }
  &:hover {
    /*@include transform(translateY(-50%) rotate(90deg)!important);*/
    &:before,
    &:after {
      background-color: $blue;
    }
  }
}

.b-ads-space {
  &.vertical {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }
  }

  &.horizontal {
    @include flexbox;
    @include padding(0);
    @include margin-bottom(30);
    @include align-items(center);
    @include justify-content(center);

    @include responsive(sm) {
      @include padding(0);
      @include margin-bottom(50);
    }

    img,
    iframe,
    object,
    video {
      width: 100%;
      max-width: 769px;
    }
  }
}

/*! Don't Remove it */
@media (max-width: 767px) {
  .img-fluid {
    width: 100%;
  }
}

.img-cover {
  width: 100%;
  height: 100%;
  @include object-fit(cover);
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}

aside {
  .b-ads-space {
    &.vertical {
      @include margin-bottom(20);
      @include responsive(sm) {
        @include margin-bottom(25);
      }
    }
    &.horizontal {
      @include margin-bottom(20);
      @include responsive(sm) {
        @include margin-bottom(25);
      }
    }
  }
}

.c-section-grey,
.c-section-white {
  @include padding(25 0 10);
  @include margin-bottom(0);

  @include responsive(sm) {
    @include padding(60 0 35);
  }
}
.c-section-grey {
  background-color: $white_sh1;
}
.c-section-white {
  background-color: $white;
}
.c-section-orange {
  @include padding(60 0 20);
  @include margin-bottom(0);
  background-color: $yellow_sh1;
  @include border-radius(40px 0 0 0);

  @include responsive(sm) {
    @include padding(110 0 30);
    @include border-radius(60px 0 0 0);
  }
}

.tab-content > .active {
  display: block;
  opacity: 1;
}

.hr {
  width: 100%;
  margin: 30px auto;
  display: inline-block;

  hr {
    margin: 0px;
    border-top: 1px solid rgba($border, 0.5);
  }

  @include responsive(sm) {
    margin: 50px auto;
  }
}

/* Breadcrumb */
.breadcrumb {
  text-align: left;
  @include padding(0);
  @include padding-top(5);
  @include margin-bottom(0);
  background-color: transparent;

  > li {
    text-align: left;
    @include margin(0);
    display: inline-block;
    @include font-size(14);
    font-weight: $weightRegular;

    > a {
      color: $white;
      @include font-size(14);
      font-weight: $weightRegular;

      &:hover,
      &:active {
        color: $yellow_sh1;
      }
    }
    &.active {
      color: $white;
      font-weight: $weightSemibold;

      > span {
        border-bottom: 1px solid $white;
      }
    }

    + li {
      &:before {
        color: $white;
        content: '/ ';
        border: 0px none;
        @include margin(0 5);
      }
    }
  }
}

.drop-arrow {
  top: 1px;
  line-height: 1;
  /*content: " ";*/
  text-shadow: none;
  position: relative;
  text-transform: none;
  font-variant: normal;
  display: inline-block;
  @include font-size(12);
  @extend .if-solid;
  color: rgba($white, 0.6);
  @include margin-left(6);
  -webkit-font-smoothing: antialiased;
}

.badge {
  @include inline-flex;
  color: $white;
  @include font-size(11);
  letter-spacing: 0.5px;
  line-height: 1.36;
  @include padding(3 9);
  @include margin-right(8);
  @include border-radius(16px);
  font-weight: $weightBold;
  text-transform: uppercase;
  @include box-shadow(0 2px 0 0 rgba($black, 0.5));
}

.gradient-green {
  @include linear-gradient(to right, $green_sh3, $green_sh4);
}
.gradient-blue {
  @include linear-gradient(to right, $blue, $blue_sh5);
}
.gradient-red {
  @include linear-gradient(to right, $red, $yellow_sh1);
}
.gradient-white {
  @include linear-gradient(to right, $white, $white);
}

[data-overlay] {
  &:before {
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
[data-overlay='0'] {
  &:before {
    background: rgba($black, 0);
  }
}
[data-overlay='1'] {
  &:before {
    background: rgba($black, 0.1);
  }
}
[data-overlay='2'] {
  &:before {
    @include linear-gradient(-117deg, rgba($black, 0.5), rgba($black, 0.25));
  }
}
[data-overlay='3'] {
  &:before {
    background: rgba($black, 0.6);
  }
}

// Alert(s)

.alert {
  line-height: 1.35;
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;

  &-danger {
    color: #ff0f82 !important;
    background-color: $dangerAlert !important;
  }

  .icon,
  .message {
    position: relative;
    vertical-align: middle;
  }
  .icon {
    margin-top: -1px;
  }

  &-heading {
    @extend .u-upper;
    @include font-size(25);
    @include margin-bottom(20);
  }
  p {
    @extend .u-upper;
    @include font-size(15);

    &:last-child {
      @include margin-bottom(0);
    }
  }
}

.opacity {
  &--0 {
    @include opacity(0);
  }
  &--10 {
    @include opacity(0.1);
  }
  &--20 {
    @include opacity(0.2);
  }
  &--30 {
    @include opacity(0.3);
  }
  &--40 {
    @include opacity(0.4);
  }
  &--50 {
    @include opacity(0.5);
  }
  &--60 {
    @include opacity(0.6);
  }
  &--70 {
    @include opacity(0.7);
  }
  &--80 {
    @include opacity(0.8);
  }
  &--90 {
    @include opacity(0.9);
  }
  &--100 {
    @include opacity(1);
  }
}

.text--ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
