$brand: #3076a9 !default;
$primary: #3076a9 !default;
$subprimary: #1b5b89 !default;
$primary_sh2: #2f55d4 !default;
$primary_sh3: darken($primary, 15%) !default;
$primary_sh4: #ddd1f4 !default;
$menu-color: #272021 !default;
$text-color: #8392a7 !default;
$footer-color: #0e132d !default;

$primaryColor: $brand !default;

$greyish: #4f4f4f !default; /* #greyish-brown */
$grey: #666667 !default; /* #brownish-grey */
$grey_sh1: #323232 !default; /* #charcoal */
$grey_sh2: #a4a7a9 !default; /* #cool-grey */
$black: #000000 !default; /* #black */
$transparent: rgba(0, 0, 0, 0) !default; /* #transparent */

$blue: #2e86fd !default; /* #sapphire */
$blue_sh1: #3589f9 !default; /* #cerulean */
$blue_sh2: #0d4df0 !default;
$blue_sh3: #ebf4f8 !default;
$blue_sh4: #243dff !default;
$blue_sh5: #79bbcd !default;
$blue_sh6: #242e60 !default;
$info: $blue_sh1 !default;

$card: #1d1d1d !default;
$modal: #0f0f0f !default;

$dark: #1d202c !default; /* #dark */

$red: #ed1c38 !default; /* #neon-red */
$red_sh1: #ff0f82 !default; /* #scarlet */
$red_sh2: #ffdfee !default; /* #scarlet */
$error: $red_sh1 !default; /* #error */

$green: #00c48c !default; /* #green-apple */
$success: #00c48c !default; /* #aqua-marine */
$green_sh1: #55910b !default; /* #grass-green */
$green_sh2: #50e3c2 !default; /* #greenish-cyan */
$green_sh3: #039b7e !default; /* #teal */
$green_sh4: #b4f000 !default; /* #lemon-green */
$hoverColor: #1b5b89 !default;
$theadColor: #f0f0f0 !default;

$yellow: #ffd700 !default; /* #sunflower-yellow */
$warning: #ffded3 !default; /* #orangey-yellow */
$warning_sh1: #ff8c69 !default;
$warning_sh2: #ff9115 !default;
$yellow_sh1: #fb8d29 !default; /* #pumpkin-orange */
$yellow_sh2: #ff5500 !default; /* #bright-orange */

$white: #ffffff !default; /* #white */
$white_sh1: #f3f4f8 !default; /* #very-light-pink */
$white_sh2: #f8f9fc !default;
$white_sh3: #f6f6f6 !default;
$offwhite: #eaeaea !default; /* #very-light-pink */

$border: #d8d8d8 !default; /* #light-periwinkle */
$border_sh1: #ebebeb !default;
$iconColor: #b1b1b1 !default;
$btnGroup: #f2f2f2 !default;

$unsuccessful: #ff3569 !default;
$blackred: #ac305f !default;
$black_sh1: #333333 !default;
$black_sh2: lighten(
  $black_sh1,
  20%
) !default; /* color code == #666666 also (0,0,0,0.6) */
$black_sh3: lighten($black_sh1, 40%) !default; /* color code == #999999 */
$black_sh4: lighten($black_sh1, 60%) !default; /* color code == #cccccc */
$black_sh5: lighten($black_sh1, 41%) !default; /* color code == #9c9c9c */
$placeholder: #c0c0c3 !default;

$facebook: #3b5998 !default;
$twitter: #00aeef !default;
$pinterest: #bd081c !default;
$googleplus: #e34829 !default;
$youtube: #d62423 !default;
$vimeo: #5bc7ff !default;
$instagram: #2c5987 !default;
$linkedin: #1f7bc2 !default;
$flickr: #377ede !default;
$lightgrey: #cdcdcd !default;
$lightborder: #e0e0e0 !default;
$wechat: #2dc100 !default;

$badgePrimary: #e5e7fa !default;
$badgePrimary_sh1: #e1f9f2 !default;

/* Widths ▸ Window Width */
$viewportSize: 100% !default;
$max-width: 1920px !default;

$fullHeight: 100vh !default;

/* Animation Speed */
$anim-speed: 500ms !default;

$general-transition-time: 300ms !default;

$slow-transition-time: 370ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;
$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s !default;

// Color system

$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #9d9d9d !default; // Line footer color
$gray-700: #52505a !default; // Line p color
$gray-800: #32325d !default; // Line heading color
$gray-900: #1a051d !default;
$dark-gray: #7b7981 !default;
$gray-1000: #3f3356 !default;

$dangerAlert: #ffe2f0 !default;
$sidebarColor: #f8f6ff !default;

.dark-gray {
  color: $dark-gray;
}
.dark-grey2 {
  color: $gray-900 !important;
}
.text-black {
  color: $black;
}
.color-primary {
  color: $primary_sh2;
}

// Sidebar variables
$logo-width: 180px !default;
$logo-small-width: 100px !default;
$logo-large-width: 250px !default;
$logo-mini-width: 80px !default;
$card-width: 300px !default;
$owl-sidebar-width: 220px !default;

// border
$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;
$border-width: 0.0625rem !default;
$border-color: $border !default;

$border-radius: 0.375rem !default;
$border-radius-xs: 0.1875rem !default;
$border-radius-sm: 0.2857rem !default;
$border-radius-lg: 0.4285rem !default;
$border-radius-xl: 0.875rem !default;

// Shadow(s)
$box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-xs: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
$sidebar-box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 30px 0 rgba(0, 0, 0, 0.35);

// Card(s)
$card-spacer-y: 1rem !default;
$card-spacer-x: 1.5rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius-sm !default;
$card-border-color: rgba($black, 0.05);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: $gray-100 !default;
$card-bg: $white !default;
$card-black-background: #27293d !default;
$card-img-overlay-padding: 1.25rem !default;

//$card-group-margin:           ($grid-gutter-width / 2);
//$card-deck-margin:            $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-indicator-checked-bg: $green !default;
$custom-toggle-slider-bg: #ddd !default;
$custom-toggle-checked-bg: theme-color('primary') !default;
$input-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

// Dimensions

$screen-xs: 767px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 991px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

/* Media Query Breakpoints */
$media-desktop: 'screen and (max-width: 1199px)';
$media-ipadPortrait: 'screen and (max-width :991px)';
$media-iPad: 'screen and (min-width : 768px) and (max-width : 991px)';
$media-ipadSmall: 'screen and (max-width :767px)';
$media-tablet: 'screen and (max-width: 575px)';
$media-phone: 'screen and (max-width : 480px)';
