/* Begin Header style(s) */

.c-header {
  $headerSelf: &;

  top: 0;
  z-index: 13;
  width: 100%;
  height: auto;
  position: fixed;
  @include padding(10 0);
  background-color: rgba($white, 0);
  @include transition(all 0.5s ease);

  @include responsive(sm) {
    height: auto;
    @include padding(10 0);

    @include respond-to(md) {
      height: auto;
      max-height: 100px;
      @include padding(15 0);
    }
  }

  &:not(.auth--page) {
    background-color: rgba($white_sh2, 1);
  }

  .container-fluid {
    @include padding-left(65);
    @include padding-right(15);

    @include responsive(sm) {
      @include padding-left(65);
      @include padding-right(20);

      @include respond-to(md) {
        @include padding-left(30);
        @include padding-right(30);

        @include respond-to(lg) {
          @include padding-left(50);
          @include padding-right(50);

          @include respond-to(xl) {
            @include padding-left(50);
            @include padding-right(50);
          }
        }
      }
    }
  }
  &__main-info {
    @include flexbox();
    position: relative;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(row, horizontal);

    @include responsive(sm) {
      @include flex-direction(row, horizontal);
    }

    @media screen and (min-width: 577px) and (max-width: 767px) {
      @include padding-left(0);
    }

    &--logo {
      display: block;
      text-align: left;
      width: $logo-width;
      max-width: $logo-width;
      @include flex(1 1 $logo-width);

      .image-fluid {
        width: 100%;
        max-width: 100%;
      }

      @include responsive(md) {
        display: block;
        width: $logo-large-width;
        max-width: $logo-large-width;
        @include flex(1 1 $logo-large-width);
      }
    }

    &--right {
      width: auto;
      min-height: 36px;
      letter-spacing: 0;
      text-align: right;
      position: relative;
      @include flexbox();
      position: relative;
      @include padding-left(10);
      @include align-items(center);

      @include responsive(md) {
        width: auto;
      }
    }
  }
  .topbar-menu {
    height: 35px;
    @include flexbox();
    @include margin-top(0);
    align-items: center;

    li {
      @include margin(0 7);

      a {
        color: $white;
        @include font-size(14);
        letter-spacing: normal;
        font-weight: $weightBold;
      }

      &:last-child {
        a {
          @extend .u-upper;
          @include padding(5 17);
          background-color: $green;
          @include border-radius(6px);
        }
      }
    }
  }

  /* Nav Controls */
  .navbar-wrapper {
    @include inline-flex;
    @include align-items(center);
  }

  .navbar-toggle {
    top: 50%;
    left: 25px;
    z-index: 10;
    position: absolute;
    @include transform(translateY(-50%));
  }

  .navbar-toggler {
    outline: 0;
    width: 27px;
    height: 27px;
    cursor: pointer;
    @include padding-left(0);
    @include padding-right(0);

    &-bar {
      width: 22px;
      height: 1px;
      margin: 0px;
      display: block;
      background-color: $brand;
      @include transition(all 0.1s ease-out);

      &:nth-child(2) {
        width: 17px;
        margin-left: 0px;
      }
      + .navbar-toggler-bar {
        margin-top: 6px;
      }
      &.bar1 {
        top: 0px;
        @include bar-animation($topbar-back);
      }
      &.bar3 {
        bottom: 0px;
        @include bar-animation($bottombar-back);
      }
    }
    &:hover {
      .navbar-toggler-bar {
        &:nth-child(2) {
          width: 22px;
        }
      }
    }
    &.toggled {
      .navbar-toggler-bar {
        width: 24px;
      }
    }
  }

  .header--back {
    color: $white;

    .c-button--Back {
      color: $white;
      > i {
        border-color: $white;
      }
    }
  }
}

.main-panel {
  height: 100%;
  position: relative;
  @include transition(all 0.3s ease);
}

body.register,
body.details,
body.payment,
body.thankyou {
  .c-header {
    background-color: rgba($black, 1);
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }
  .c-header {
    .navbar-toggle {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .c-header {
    .toggled {
      .bar1 {
        top: 6px;
        @include topbar-x-rotation();
        @include bar-animation($topbar-x);
      }
      .navbar-toggler-bar {
        + .navbar-toggler-bar {
          @include margin-top(-1);
        }
      }
      .bar2 {
        width: 17px;
        @include opacity(0);
        transition: width 0.2s linear;
      }
      .bar3 {
        bottom: 6px;
        @include bottombar-x-rotation();
        @include bar-animation($bottombar-x);
      }
    }
  }
  .nav-open {
    .menu--overlay {
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 2;
      bottom: 0px;
      content: '';
      position: absolute;
      background-color: rgba($black, 0.3);
    }
    .c-header {
      z-index: 2;
      .navbar-toggle {
        z-index: 10;
        .navbar-toggler {
          z-index: 10;
        }
      }
    }
    .navSidebar {
      visibility: visible;
      box-shadow: $sidebar-box-shadow;
      @include transform(translateX(100%));
    }
    body {
      position: relative;
      overflow-x: hidden;
    }
    .main-panel {
      right: 0;
      @include transform-translate-x(240px);
    }
  }
}

.wrapper {
  &[data-bg='white'] {
    .c-header {
      background: rgba($white, 1);
      background-color: rgba($white, 1);
      @include box-shadow(0 2px 16px 0 rgba($black, 0.15));

      @include responsive(sm) {
        background-color: rgba($white, 0);
        @include box-shadow(0 2px 16px 0 rgba($black, 0));

        @include respond-to(md) {
          background-color: rgba($white, 0);
          @include box-shadow(0 2px 16px 0 rgba($black, 0));
        }
      }

      &.header--sticky {
        top: 0;
        position: fixed;
        @include padding(7.5 0);
        background-color: rgba($white, 1);
        @include box-shadow(0 2px 16px 0 rgba($black, 0.15));
      }
    }
  }
}

/* End Header style(s) */
